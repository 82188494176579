import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux"; 
import { persistReducer } from 'redux-persist'

import layoutReducer from './layout/reducer'
import mealReducer from './meal/reducer'
import authReducer from './auth/reducer'
import userReducer from './user/reducer'
import orderReducer from './order/reducer'
import walletReducer from './wallet/reducer'

const reducers = combineReducers({
    layout: layoutReducer,
    meal: mealReducer,
    auth: authReducer,
    user: userReducer,
    order: orderReducer,
    wallet: walletReducer
});

const persistConfig = {
    key: 'bytes-aggregator-root',
    storage
};

const persistedReducer = persistReducer(persistConfig, reducers);
 
const store = configureStore({
    reducer: persistedReducer, 
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})

export default store