
export const SNACKBAR_SEVERITY = {
    ERROR : 'error',
    INFO: 'info',
    WARNING: 'warning', 
    SUCCESS: 'success', 
};


export const FOOTER_LINKS = {
    ABOUT_US : 'https://the-bytes.com/about-us/',
    FAQS: 'https://the-bytes.com/home/faq/',
    PRIVACY_POLICY : 'https://the-bytes.com/terms-and-conditions/',
    FACEBOOK_URL : 'https://www.facebook.com/bytesthedeliciousproject/',
    INSTAGRAM_URL : 'https://www.instagram.com/bytes_thedeliciousproject/',
    TWITTER_URL : 'https://twitter.com/bytes_foodstory',
    LINKEDIN_URL : 'https://www.linkedin.com/company/the-bytes/',
    YOUTUBE_URL : 'https://www.youtube.com/channel/UCnEMaOim8duaTOUYvcUF4CQ',
    PLAY_STORE_URL : 'https://play.google.com/store/apps/details?id=in.thebytes.app'
};

export const POST_AUTH_ACTION = {
    CLAIM_ORDER: 'CLAIM_ORDER',
    RELOAD_WALLET : 'RELOAD_WALLET',
};

export const CLAIM_ORDER_STATUS = {
    IN_PROGRESS : 'In Progress',
    SUCCESS: 'Success',
    FAIL : 'Fail'
};

export const STATUS = {
    LOADING :'loading',
    DONE: 'done'
};

export const HEADER_TABS = {
    CONSUMPTION : {
        'key' : 'consumption',
        'label' : 'Consumption',
    },
    MEAL : {
        'key' : 'meal',
        'label' : 'Meal',
    },
    SUBSCRIPTION : {
        'key' : 'subscription',
        'label' : 'Subcription',
    },

}

export const chartColors = [
    'rgba(54, 162, 235, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 99, 132, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(255, 159, 64, 1)'
];

export const chartColorEmpty = "#fefefe";

export const mealFilters = {
    CUISINE : [
        {
            'key' : 'indian',
            'value' : 'Indian'
        },
        {
            'key' : 'fusion',
            'value' : 'Fusion'
        },
        {
            'key' : 'continental',
            'value' : 'Continental'
        },
    ],
    PREFERENCE: [
        {
            'key' : 'vegetarian',
            'value' : 'Vegetarian',
        },
        {
            'key' : 'non-vegetarian',
            'value' : 'Non Vegetarian',
        },
        {
            'key' : 'eggetarian',
            'value' : 'Eggetarian',
        },
    ]
}

export const dishList = [
    {
        'name' : 'Item 1',
        'display_name' : 'Item 1',
        'description' : 'This is the description of the itemThis is the description of the itemThis is the description of the item',
        'image_url' : '',
        'nutrition_info' : [
            {
                'name' : 'protein',
                'display_name' : 'Protein',
                'value' : '28',
                'primary_unit': 'gm',
                'primary_unit_display_name' : 'GM'
            },
            {
                'name' : 'carbs',
                'display_name' : 'Carbs',
                'value' : '32',
                'primary_unit': 'gm',
                'primary_unit_display_name' : 'GM'
            },
            {
                'name' : 'fat',
                'display_name' : 'Fat',
                'value' : '12',
                'primary_unit': 'gm',
                'primary_unit_display_name' : 'GM'
            },
            {
                'name' : 'calories',
                'display_name' : 'Calories',
                'value' : 280,
                'primary_unit': 'kcal',
                'primary_unit_display_name' : 'kCal'
            },
            {
                'name' : 'fiber',
                'display_name' : 'Fiber',
                'value' : '20',
                'primary_unit': 'gm',
                'primary_unit_display_name' : 'GM'
            },
        ]
    }
];

export const orderDetails = {
    'order_id' : '1231231232',
    'order_dt' : '2022-09-28 09:01:23',
    'nutrition_info': [
        {
            'name' : 'protein',
            'display_name' : 'Protein',
            'value' : 28,
            'primary_unit': 'gm',
            'primary_unit_display_name' : 'GM',
            'acheived_percentage' : 50,
        },
        {
            'name' : 'carbs',
            'display_name' : 'Carbs',
            'value' : 32,
            'primary_unit': 'gm',
            'primary_unit_display_name' : 'GM',
            'acheived_percentage' : 40,
        },
        {
            'name' : 'fat',
            'display_name' : 'Fat',
            'value' : 12,
            'primary_unit': 'gm',
            'primary_unit_display_name' : 'GM',
            'acheived_percentage' : 30,
        },
        {
            'name' : 'calories',
            'display_name' : 'Calories',
            'value' : 280,
            'primary_unit': 'kcal',
            'primary_unit_display_name' : 'kCal',
            'acheived_percentage' : 60,
        },
        {
            'name' : 'fiber',
            'display_name' : 'Fiber',
            'value' : 20,
            'primary_unit': 'gm',
            'primary_unit_display_name' : 'GM',
            'acheived_percentage' : 80,
        }
    ],
    'order_items': [
        {
            'name' : 'Item 1',
            'display_name' : 'Item 1',
            'description' : 'This is the description of the itemThis is the description of the itemThis is the description of the item',
            'image_url' : '',
            'tags' : [
                {
                    'key' : 'preference',
                    'value' : ['Vegetarian']
                },
                {
                    'key' : 'cuisine',
                    'value' : ['Indian', 'Fusion']
                }
            ],
            'nutrition_info' : [
                {
                    'name' : 'protein',
                    'display_name' : 'Protein',
                    'value' : 28,
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM',
                },
                {
                    'name' : 'carbs',
                    'display_name' : 'Carbs',
                    'value' : 32,
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'fat',
                    'display_name' : 'Fat',
                    'value' : 12,
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'calories',
                    'display_name' : 'Calories',
                    'value' : 280,
                    'primary_unit': 'kcal',
                    'primary_unit_display_name' : 'kCal'
                },
                {
                    'name' : 'fiber',
                    'display_name' : 'Fiber',
                    'value' : 20,
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
            ]
        },
        {
            'name' : 'Item 1',
            'display_name' : 'Item 1',
            'description' : 'This is the description of the itemThis is the description of the itemThis is the description of the item',
            'image_url' : '',
            'tags' : [
                {
                    'key' : 'preference',
                    'value' : ['Vegetarian']
                },
                {
                    'key' : 'cuisine',
                    'value' : ['Indian', 'Fusion']
                }
            ],
            'nutrition_info' : [
                {
                    'name' : 'protein',
                    'display_name' : 'Protein',
                    'value' : '28',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM',
                },
                {
                    'name' : 'carbs',
                    'display_name' : 'Carbs',
                    'value' : '32',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'fat',
                    'display_name' : 'Fat',
                    'value' : '12',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'calories',
                    'display_name' : 'Calories',
                    'value' : 280,
                    'primary_unit': 'kcal',
                    'primary_unit_display_name' : 'kCal'
                },
                {
                    'name' : 'fiber',
                    'display_name' : 'Fiber',
                    'value' : '20',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
            ]
        },
        {
            'name' : 'Item 1',
            'display_name' : 'Item 1',
            'description' : 'This is the description of the itemThis is the description of the itemThis is the description of the item',
            'image_url' : '',
            'tags' : [
                {
                    'key' : 'preference',
                    'value' : ['Vegetarian']
                },
                {
                    'key' : 'cuisine',
                    'value' : ['Indian', 'Fusion']
                }
            ],
            'nutrition_info' : [
                {
                    'name' : 'protein',
                    'display_name' : 'Protein',
                    'value' : '28',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM',
                },
                {
                    'name' : 'carbs',
                    'display_name' : 'Carbs',
                    'value' : '32',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'fat',
                    'display_name' : 'Fat',
                    'value' : '12',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
                {
                    'name' : 'calories',
                    'display_name' : 'Calories',
                    'value' : 280,
                    'primary_unit': 'kcal',
                    'primary_unit_display_name' : 'kCal'
                },
                {
                    'name' : 'fiber',
                    'display_name' : 'Fiber',
                    'value' : '20',
                    'primary_unit': 'gm',
                    'primary_unit_display_name' : 'GM'
                },
            ]
        }
    ]
}

export const LOGO = {
    BYTES : 'https://the-bytes.com/wp-content/uploads/2022/01/3.png',
    ZOMATO: 'https://upload.wikimedia.org/wikipedia/commons/7/75/Zomato_logo.png',
    SWIGGY : 'https://1000logos.net/wp-content/uploads/2021/05/Swiggy-logo.png',
    DUNZO : 'https://assets.dunzo.com/images/logo-512.png',
    AMAZON : 'https://1000logos.net/wp-content/uploads/2016/10/Amazon-logo-meaning.jpg',
};
