import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: null,
    session: null,
    isLoggedIn: false,
    userDetails: null,
    userId:null,
    userName: null,
    userDetailSubmitted : false
  },
  reducers: {
    setUser(state,action) {
      state.userDetails = action.payload.data; 
      state.userId = action.payload.data.id;
    },
    setUserId(state,action) {
      state.userId = action.payload.data; 
    },
    setUserName(state,action) {
      state.userName = action.payload.data; 
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload.data;
    },
    setSessionDetails(state, action) {
      state.session = action.payload.data;
    },
    setLoginStatus(state,action) {
      state.isLoggedIn = action.payload.data; 
    },
    setUserDetailSubmitted(state,action) {
      state.isLoggedIn = action.payload.data; 
    },
    logoutUser(state, action){
      state.accessToken = "";
      state.session = "";
      state.isLoggedIn = false;
      state.userId = "";
      state.userName = "";
      state.userDetails = {};
    },
  }
})

export const getAccessToken = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.accessToken;
  }
  return null;
}

export const getSessionDetails = (state) => {

  if(state.user != null && state.user !== undefined){
    return state.user.session;
  }
  return null;
}

export const getUser = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userDetails;
  }
  return null;
}

export const getUserId = (state) => {
  if(state.user != null && state.user !== undefined){
    return state.user.userId;
  }
  return null;
}

export const getUserName = (state) => {
  if(state.user != null && state.user !== undefined &&  state.user.userDetails !== null){
    return state.user.userDetails.user_name;
  }
  return null;
}

export const getLoginStatus = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.isLoggedIn;
    // return (state.user.session != null && state.user.session!==undefined);
  }
  return false;
}

export const getUserDetailSubmitted = (state) => {

  if(state.user != null && state.user !== undefined){
      return state.user.userDetailSubmitted;
  }
  return null;
}

export const { setAccessToken, setSessionDetails, setLoginStatus, setUser, 
    setUserName, setUserId, logoutUser, setUserDetails, setUserDetailSubmitted
} = userSlice.actions
export default userSlice.reducer