import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../constants/app_constants';

const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    details: null,
    transactions: null,
    transaction_pagination : null,
    current_transaction_page: 1
  },
  reducers: {
    setWalletDetails(state, action){
      state.details = action.payload.data;
    },
    setWalletTransaction(state, action){
      state.transactions = action.payload.data;
    },
    setWalletTransactionPagination(state, action){
      state.transaction_pagination = action.payload.data;
    }, 
    appendWalletTransaction(state, action){
      const updated_transactions = [...state.transactions, ...action.payload.data]
      state.transactions = updated_transactions;
    }
  }
})

export const getWalletDetails = (state) => {
  if(state.wallet != null && state.wallet !== undefined){
    return state.wallet.details;
  }
  return null;
}

export const getWalletTransaction = (state) => {
  if(state.wallet != null && state.wallet !== undefined){
    return state.wallet.transactions;
  }
  return null;
}

export const getWalletTransactionPagination = (state) => {
  if(state.wallet != null && state.wallet !== undefined){
    return state.wallet.transaction_pagination;
  }
  return null;
} 

export const { setWalletTransaction, setWalletDetails, setWalletTransactionPagination, 
   appendWalletTransaction } = walletSlice.actions
export default walletSlice.reducer
