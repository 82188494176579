import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Grid, SwipeableDrawer } from '@mui/material';
import ReactGA from 'react-ga';

// components
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginFormVisibility, setLoginFormVisibility } from '../store/auth/reducer';
import { getLoginStatus } from '../store/user/reducer';
import { useState } from 'react';
import Iconify from '../components/Iconify';
import SideMenu from '../components/menu/SideMenu';
import LoginForm from '../sections/login/LoginForm';
import { logEvent } from '../utils/analytics';
import { ANALYTICS_EVENTS } from '../constants/analytics_events';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

// ----------------------------------------------------------------------

export default function NavHeaderLayout() {

    const isLoggedIn = useSelector(state => getLoginStatus(state));
    const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);
    const isLoginDrawerOpen = useSelector(state => getLoginFormVisibility(state));

    const dispatch = useDispatch();

    const toggleLoginModal = (anchor , open ) => (event ) => {
        dispatch(setLoginFormVisibility({data: open}));
    
        if (
            event &&
            event.type === 'keydown' &&
            ((event ).key === 'Tab' ||
            (event ).key === 'Shift')
        ) {
            return;
        }   
    };
    
    const toggleMenuModal = (anchor , open ) => (event ) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event ).key === 'Tab' ||
          (event ).key === 'Shift')
        ) {
            return;
        }

        setIsMenuDrawerOpen(open); 
    };
    
    return (
        <div className="App" style={{minHeight:'100vh'}}>
            {
                isLoggedIn
                ? <Grid className="nav-header" container justifyContent="space-between" alignContent="center" sx={{height:'10vh'}}>
                    <Grid item>
                    <Logo />
                    </Grid>
                    <Grid item>
                    <Iconify icon="eva:menu-outline" sx={{height:'40px', width:'40px', margin:'10px'}} onClick={() => {
                        setIsMenuDrawerOpen(true);
                    }}></Iconify>
                    </Grid>
                </Grid>
                : <Grid className="nav-header" container justifyContent="space-between" alignContent="center" sx={{height:'10vh'}}>
                    <Grid item>
                    <Logo />
                    </Grid>
                    <Grid item>
                    <Button variant="outlined" sx={{margin:'10px'}} onClick={() => {
                        logEvent(ANALYTICS_EVENTS['auth']['login-button-clicked']);
                        dispatch(setLoginFormVisibility({data: true}));
                    }}>Login</Button>
                    </Grid>
                </Grid>
            }
    
            <Outlet />

            <div className="bottom-spacer"/>
            <Footer />
            <div className="bottom-spacer"/>


            <SwipeableDrawer
                anchor={'bottom'}
                open={isLoginDrawerOpen}
                onClose={toggleLoginModal('bottom', false)}
                onOpen={toggleLoginModal('bottom', true)}
                sx={{zIndex: 1001}}
                style={{zIndex: 10011}}
            >
                <LoginForm handleClose={toggleLoginModal('bottom', false)} />
            </SwipeableDrawer>

            {
                isLoggedIn
                ? <SwipeableDrawer
                    anchor={'right'}
                    open={isMenuDrawerOpen}
                    onClose={toggleMenuModal( 'right', false)}
                    onOpen={toggleMenuModal( 'right', true)}
                    sx={{zIndex: 1001}}
                    style={{zIndex: 10011}}
                >
                    <SideMenu handleClose={toggleMenuModal( 'right', false)} />
                </SwipeableDrawer>
                : null
            }
        </div>
    );
}
