export const API_BASE_URL="https://api.the-bytes.com/";
// export const API_BASE_URL="https://staging.greckle.io/";

export const API_SEND_OTP="v1/otp";
export const API_VERIFY_OTP="v1/otp";
export const API_GENERATE_ACCESS_TOKEN="v1/oauth/token";
export const API_CREATE_SESSION = "v1/sessions/add";
export const API_GET_SESSION="v1/sessions";

export const API_GET_ORDER_DETAILS="v1/aggregator_orders/";
export const API_CLAIM_ORDER="v1/aggregator_orders/claim";
export const API_GET_MEAL_SUGGESTION="v1/aggregator_orders/meal_suggestion";
export const API_GET_ORDER_HISTORY="v1/aggregator_orders/history";

export const API_GET_USER_DETAILS="v1/users/";
export const API_UPDATE_USER_DETAILS="v1/users/";
export const API_GET_REWARDS="v1/users/rewards";

export const API_GET_WALLET_TRANSACTION = "v1/wallet/transaction";
export const API_GET_WALLET = "v1/wallet";

