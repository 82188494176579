export const tagManagerArgs = {
    gtmId: 'GTM-5BH8XBN'
};

export const GA_PROPERTY_ID = '4121774233';
export const GA_MEASUREMENT_ID = 'G-NST7XWV79K';

export const ANALYTICS_EVENTS = {
    'auth' : {
        'login-button-clicked' : {
            'event' : 'login_btn_click',
            'category' : 'btn_click',
            'label': 'Login Button Clicked'
        },
        'form-visible' : {
            'event' : 'login_form_visible',
            'category' : 'page_view',
            'label': 'Login Form Visible'
        },
        'send-otp-clicked' : {
            'event' : 'send_otp_btn_click',
            'category' : 'btn_click',
            'label': 'Send OTP Button Clicked'
        },
        'send-otp-ack-success' : {
            'event' : 'send_otp_api_response_success',
            'category' : 'api_response',
            'label': 'Send OTP Success'
        },
        'send-otp-ack-error' : {
            'event' : 'send_otp_api_response_error',
            'category' : 'api_response',
            'label': 'Send OTP Error'
        },
        'verify-otp-clicked' : {
            'event' : 'verify_otp_btn_click',
            'category' : 'btn_click',
            'label': 'Verify OTP Button Clicked'
        },
        'verify-otp-ack-success' : {
            'event' : 'verify_otp_api_response_success',
            'category' : 'api_response',
            'label': 'Verify OTP Success'
        },
        'verify-otp-ack-error' : {
            'event' : 'verify_otp_api_response_error',
            'category' : 'api_response',
            'label': 'Verify OTP Error'
        },
        'verify-otp-ack-incorrect' : {
            'event' : 'verify_otp_api_response_incorrect',
            'category' : 'api_response',
            'label': 'Incorrect OTP'
        },
        'logout-button-clicked':{
            'event' : 'logout_btn_click',
            'category' : 'btn_click',
            'label': 'Logout Button Clicked'
        }
    },
    'page-view': {
        'order-details': {
            'event' : 'order_details_page_view',
            'category' : 'page_view',
            'label' : 'Order Details Visible'
        },
        'side-menu': {
            'event' : 'side_menu_page_view',
            'category' : 'page_view',
            'label' : 'Side Menu Visible'
        },
    },
    'order-details': {
        'order-details-ack-success' : {
            'event': 'order_details_api_response_success',
            'category': 'api_response',
            'label': 'Order Details Success'
        },
        'order-details-ack-error' : {
            'event': 'order_details_api_response_error',
            'category': 'api_response',
            'label': 'Order Details Error'
        }
    },
    'meal-suggestion':{
        'meal-suggestion-ack-success' : {
            'event': 'meal_suggestion_api_response_success',
            'category': 'api_response',
            'label': 'Meal Suggestion Success'
        },
        'meal-suggestion-ack-error' : {
            'event': 'meal_suggestion_api_response_error',
            'category': 'api_response',
            'label': 'Meal Suggestion Error'
        }
    },
    'claim-order':{
        'claim-order-clicked' : {
            'event': 'claim_order_btn_click',
            'category': 'btn_click',
            'label': 'Claim Order Clicked'
        },
        'claim-order-ack-success' : {
            'event': 'claim_order_api_response_success',
            'category': 'api_response',
            'label': 'Claim Order Success'
        },
        'claim-order-ack-error' : {
            'event': 'claim_order_api_response_error',
            'category': 'api_response',
            'label': 'Claim Order Error'
        }
    }
}