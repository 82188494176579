import { useState, useRef, useEffect } from 'react';
// @mui
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack,List, ListItem, ListItemText, Fab } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import {DonutChart} from 'react-circle-chart';
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';

import '../../assets/css/Aggregator.css';
import Iconify from '../../components/Iconify';
import OrderItemCard from '../../components/cards/OrderItem';
import { chartColorEmpty, chartColors, CLAIM_ORDER_STATUS, POST_AUTH_ACTION } from '../../constants/app_constants';
import { getOrderClaimStatus, getOrderDetails, getOrderId, setClaimOrderStatus } from '../../store/order/reducer';
import { setLoginFormVisibility, setPostAuthAction } from '../../store/auth/reducer';
import { claimOrderApi, fetchOrderDetailsApi } from '../../data/network/order';
import { getLoginStatus } from '../../store/user/reducer';
import { logEvent } from '../../utils/analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics_events';


const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: theme.spacing(1, 0),
  }));
  

// ----------------------------------------------------------------------

export default function ConsumptionSection() {  

    // ChartJS.register(ArcElement, Tooltip, Legend);

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const isLoggedIn = useSelector(state => getLoginStatus(state));
    const orderId = useSelector(state => getOrderId(state));
    const orderDetails = useSelector(state => getOrderDetails(state));

    const [orderClaimStatus, setOrderClaimStatus] = useState(false);
    const orderClaimed = useSelector(state => getOrderClaimStatus(state));

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if(orderClaimed){
            fetchOrderDetailsApi(dispatch, cookies, orderId);
        }
    },[orderClaimed]);

    useEffect(() => {
        try{
            setTimeout(() =>{
                document.getElementById('report-item-list-3').scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            },3000);
        }catch(e){
            console.log(e);
        }
        
    },[orderId])


    useEffect(() => {
        try{
            const _chartData = [];
            if(orderDetails && orderDetails !== null && orderDetails.nutrition_info !== null){
                orderDetails.nutrition_info.forEach((element, i) => {
                    let chartValue = 0;
                    try{
                        chartValue = parseInt(element.achievement_percentage);
                    }catch(e){
                        chartValue = 0;
                    }
                    _chartData.push({
                        'display_name' : element.display_name,
                        'value' : element.value,
                        'unit' : element.primary_unit_display_name,
                        'chart' : [
                            {
                                'value' : chartValue,
                                'label' : element.display_name,
                                'color': chartColors[i],
                                // 'displayValue' : "123"
                            }
                        ]
                    });
                });    
                setChartData(_chartData);
            }

            if(orderDetails && orderDetails.user && orderDetails.user.id ){
                setOrderClaimStatus(true);
            }
        }catch(e){
            console.log(e);
        }
    },[orderDetails]);

    // const orderDetails = useSelector(state => getOrderDetails(state));

    return (
        <Container id="consumption_section" maxWidth="xl" padding={0} className="consumption-detail">
            <ContentStyle>
                {
                    orderDetails && orderDetails.nutrition_info && orderDetails.nutrition_info.length>0
                    && <>
                        <Typography variant="h4" marginLeft={2}>This Meal</Typography>
                        <Card sx={{margin:2}} className="graph-view glass-box">
                            <List className="report-item-list" component={Stack} direction="row">
                                {
                                    chartData.map((e,i) => (
                                        <ListItem key={i} id={`report-item-list-${i}`}>
                                            <ListItemText className="nutrition-detail">
                                                <Grid container direction="column" alignItems="space-around" justifyContent="space-between">
                                                    <Grid item>
                                                        <Typography className="nutrition-label" variant="h4">{e.display_name}</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Box margin={2}></Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <DonutChart size={80}
                                                            totalTextColor='#d4d9d2'
                                                            totalFontSize='12px'
                                                            items={e.chart}                                                    
                                                            trackColor='rgba(20, 21, 23, 0.12)'
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Box margin={2}></Box>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography className='nutrition-value' variant="h4">{`${parseFloat(e.value).toFixed(2)} ${e.unit}`}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItem>    
                                    ))
                                }
                            </List>
                        </Card> 
                    </>
                }
                <Box margin={1}></Box>
                {  
                    orderDetails && orderDetails.order_items && orderDetails.order_items.length > 0
                    && <Typography variant="h4" marginLeft={2} marginBottom={2}>Order Items</Typography>
                }
                <Box sx={{marginLeft:2, marginRight:2}}>
                    <Grid container>
                        {
                            orderDetails && orderDetails.order_items && orderDetails.order_items.map((e,i) => (
                                OrderItemCard({dish: e, key:i})
                            ))
                        }
                    </Grid>
                </Box>
                {
                    !orderClaimStatus &&
                    <Fab className="btn-claim-reward" variant="extended" color="primary" onClick={() => {
                        logEvent(ANALYTICS_EVENTS['claim-order']['claim-order-clicked']);

                        if(!isLoggedIn){
                            dispatch(setPostAuthAction({data: [
                                POST_AUTH_ACTION.CLAIM_ORDER
                            ]}));
                            dispatch(setLoginFormVisibility({data: true}));
                        }else{
                            console.log("123");
                            claimOrderApi(dispatch, cookies, {'order_id' : orderId});
                        }
                    }}>
                        <Iconify icon="eva:gift-outline" sx={{fontSize:'24px'}}></Iconify>
                        <Typography variant="h6" ml={1}>Claim this meal</Typography>
                    </Fab>
                }
            </ContentStyle>
        </Container>
    );
}
