import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory ,useLocation, Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Fab, Grid, SwipeableDrawer, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie'; 
import { useBottomScrollListener } from 'react-bottom-scroll-listener'; 

// hooks

// components
import '../../assets/css/Aggregator.css';
import '../../assets/css/Aggregator2.css';
import { fetchWalletTransaction } from '../../data/network/wallet';
import { getWalletTransaction, getWalletTransactionPagination } from '../../store/wallet/reducer';
import { formatDate } from '../../utils/date';
import { LOGO } from '../../constants/app_constants';
// ----------------------------------------------------------------------

export default function WalletTransaction() {

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const walletTransaction = useSelector(state => getWalletTransaction(state));
  const walletTransactionPagination = useSelector(state => getWalletTransactionPagination(state));

  const [page, setPage] = useState(1); 
 
  useEffect(() => {
    if(page > 1 && page <= walletTransactionPagination.total_pages){
        fetchWalletTransaction(dispatch, cookies, page);
    }else{
        fetchWalletTransaction(dispatch, cookies, page);
    }
  },[page])

  const handleContainerOnBottom = useCallback(() => {
    console.log('I am at bottom in optional container! ');
  }, []);

  const containerRef = useBottomScrollListener(handleContainerOnBottom, { triggerOnNoScroll: false });

  const renderLogo = (transaction) => {

    let img_src = LOGO.BYTES;

    if(transaction != null){
        if(transaction.aggregator_order !== null && transaction.aggregator_order.aggregator !== null){
            switch(transaction.aggregator_order.aggregator.toUpperCase()){
                case 'ZOMATO':
                    img_src = LOGO.ZOMATO
                    break;
                case 'SWIGGY':
                    img_src = LOGO.SWIGGY
                    break;
                case 'DUNZO':
                    img_src = LOGO.DUNZO
                    break;
                case 'AMAZON':
                    img_src = LOGO.AMAZON
                    break;
                default:
                    break;
            }
        }
    }

    return <img width="20" height="20" src={img_src} style={{borderRadius:'50px'}} />
  }

  return (
    <Grid container className="" justifyContent="start" alignItems="start"  ref={containerRef}>
       <Typography variant="h3" sx={{color: '#000', padding:2}}>My Transactions</Typography>
       {
        walletTransaction && walletTransaction !== null && walletTransaction !== undefined
        && walletTransaction.length > 0 
        ? walletTransaction.map((transaction, i) => {
            return <Grid key={i} container flexDirection="row" justifyContent="space-between" alignContent="start" sx={{padding:2, color:'#000'}}>
                <Grid flexDirection="row" justifyContent="space-between" alignContent="center" alignItems="center" sx={{display:'flex'}}>
                    <Grid alignItems="center" alignContent="center">
                        {renderLogo(transaction)}
                    </Grid>
                    <Grid justifyContent="start" alignItems="center" style={{paddingLeft:'20px'}}>
                        <Grid>
                            <Typography sx={{fontWeight:'500'}}>{transaction.type}</Typography>
                        </Grid>
                        <Grid>
                            {
                                transaction.type === "CREDIT" 
                                ? <Typography sx={{color:'green', fontWeight:'500'}}>+ {transaction.amount}</Typography>
                                : <Typography sx={{color:'red', fontWeight:'500'}}>- {transaction.amount}</Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid>
                        <Grid>
                            <Typography>{transaction.transaction_dt && formatDate(transaction.transaction_dt, "DD MMM YYYY")}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        })
        : <Grid container flexDirection="column" justifyContent="center" alignContent="center" alignItems="center"  className="" sx={{minHeight:'30vh'}} >
            <Typography variant="h5" sx={{color:'#0e0e0e', paddingTop:'10px'}}>No records found</Typography>
            <Box height={10}></Box>
            <Button variant="outlined" onClick={() => {
                fetchWalletTransaction(dispatch, cookies, 1);
            }}>
                Reload
            </Button>
        </Grid>
       }
       <Grid container justifyContent="center" alignItems="center">
        {
            walletTransactionPagination && walletTransactionPagination !== null
            && walletTransactionPagination !== undefined && walletTransactionPagination.total_pages > page
            ? <Grid>
                <Button variant="outlined" color="primary" onClick={() => {
                    setPage(page+1);                 
                }} sx={{marginBottom:'50px'}}>Load More</Button>
              </Grid>
            : <></>
        }
       </Grid>
    </Grid>
  );
}