import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box, Grid } from '@mui/material';
// components
import Page from '../components/Page';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '90vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Home() {
  return (
    <Page title="Bytes - The Delicious Project">
      <Container>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>

          <Typography variant="h4" sx={{ color: 'text.secondary' }}>
            Welcome to
          </Typography>

          <Typography variant="h4" paragraph>
            Bytes - The Delicious Project!
          </Typography>

            <Box
                component="img"
                src="/static/illustrations/mobile_app.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
            />
        <center>
        <a href='https://play.google.com/store/apps/details?id=in.thebytes.app&utm_source=aggregator-app&utm_campaign=organic&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img width="60%" height="60%" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a></center>
{/* 
          <Grid container justifyContent="center" >
            <Box sx={{width:'40%'}}>
              <Button fullWidth variant="contained" onClick={() => {
                  window.open("https://the-bytes.com");
                }}>Go to Home
              </Button>
            </Box>
          </Grid> */}
          {/* <Button to="/" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button> */}
        </ContentStyle>
      </Container>
    </Page>
  );
}
