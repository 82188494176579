import { DuoSharp } from "@mui/icons-material";
import { Grid,Card, Typography, Box, List, ListItem, ListItemText, Stack } from "@mui/material";

import '../../assets/css/SuggestedDishCard.css';

export default function SuggestedDishCard(props) {
    const {dish, key} = props;

    const nutrition_info = []
    let calorie_count = null;
    let dish_preference = null;
    let dish_color = null;

    if(dish.nutrition_info){

        dish.nutrition_info.forEach(element => {
            if(element.name === 'calories'){
                calorie_count = element.value + " " + element.primary_unit_display_name;
            }else{
                nutrition_info.push(element)
            }
        });
    }

    // let img_url = "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
    let img_url = "/static/images/placeholder/dish.png";
    if(dish.image_url && dish.image_url !== null){
        img_url = dish.image_url;
    }
    

    if(dish.tags){
        dish.tags.forEach(element => {
            if(element.name === 'preference'){
                if(element.value.indexOf('vegetarian') !== -1){
                    dish_preference = 'vegetarian';
                    dish_color = 'green';
                }
                if(element.value.indexOf('eggetarian') !== -1){
                    dish_preference = 'eggetarian';
                    dish_color = 'yellow';
                }
                if(element.value.indexOf('non_vegetarian') !== -1){
                    dish_preference = 'non_vegetarian';
                    dish_color = 'red';
                }
            }
        });
    }

    return (
        <Grid key={key} item container className="w-100">
            <Card className="glass-box w-100 suggestion-meal-item">
                <Box>
                    <Grid container direction="row">
                        <Grid item className="w-100">
                            <img width="100%" src={img_url} alt={dish.display_name} />
                        </Grid>
                        <Grid item padding={1}>
                            <Typography variant="h5">
                                {dish.display_name}
                            </Typography>

                            <Typography>
                                {dish.description}
                            </Typography>
                        </Grid>
                    </Grid>

                    <List className="nutrition-detail" component={Stack} direction="row">
                        {
                            nutrition_info.length > 0 &&
                            nutrition_info.map((n,i) => (
                                <ListItem key={i}>
                                    <ListItemText>
                                        <Box>
                                            <Typography variant="h6" className="nutrition-value">
                                                {n.value} {n.primary_unit_display_name}
                                            </Typography>
                                            <Typography variant="p" className="nutrition-label">
                                                {n.display_name}
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                </ListItem>      
                            ))
                        }
                    </List>
                </Box>
            </Card>
        </Grid>
    );
}