import { useEffect, useState, useRef } from 'react';
import { useHistory ,useLocation, Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Fab, Grid, SwipeableDrawer, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Page from '../components/Page';
import Logo from '../components/Logo';

import '../assets/css/Aggregator.css';
import { HEADER_TABS, STATUS } from '../constants/app_constants';
import ConsumptionSection from '../sections/dashboard/ConsumptionSection';
import MealSection from '../sections/dashboard/MealSection';
import SubscriptionSection from '../sections/dashboard/SubscriptionSection';
import Footer from '../components/Footer';

import '../assets/css/Aggregator2.css';
import { fetchMealSuggestionApi, fetchOrderDetailsApi, fetchOrderHistoryApi } from '../data/network/order';
import { getOrderDetails, getOrderFetchStatus, getOrderHistory, getOrderId, getSuggestedMeals, setOrderFetchStatus, setOrderId } from '../store/order/reducer';
import LoginForm from '../sections/login/LoginForm';
import { getLoginStatus } from '../store/user/reducer';
import Iconify from '../components/Iconify';
import { getLoginFormVisibility, setLoginFormVisibility } from '../store/auth/reducer';
import SideMenu from '../components/menu/SideMenu';
import NotFound from '../sections/404/NotFound';
import { getLoadingStatus } from '../store/layout/reducer';
import { API_GET_ORDER_HISTORY } from '../data/network/endpoints';
// ----------------------------------------------------------------------


export default function OrderHistory() {

  const [visibleSection, setVisibleSection] = useState("Consumption");

  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const orderHistory = useSelector(state => getOrderHistory(state));

  useEffect(() => {
    fetchOrderHistoryApi(dispatch, cookies);
  },[])

  return (
    orderHistory && orderHistory !==null && orderHistory.length>0
    ? <></>
    : <Grid container justifyContent="center" alignContent="center" className="" sx={{minHeight:'100vh'}} >
      <Typography variant="h6">No Order found</Typography>
    </Grid>
  );

}