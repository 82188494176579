
import {Buffer} from 'buffer';

import {
    setAccessToken,
    setSessionDetails,
    setLoginStatus,
    setUserName
} from '../../store/user/reducer';


import { SNACKBAR_SEVERITY } from '../../constants/app_constants';
import axios from '../../utils/axios-configure';

import { fetchUserDetails } from './user';
import { API_SEND_OTP, API_VERIFY_OTP,API_GET_SESSION,API_CREATE_SESSION, API_GENERATE_ACCESS_TOKEN } from "./endpoints";
import { OTP_SUCCESS, OTP_ERROR } from '../../constants/message_constants';

import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import { setSendOTPResponse,  clearOTPState, setVerifyOTPResponse} from '../../store/auth/reducer';
import { logEvent } from '../../utils/analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics_events';


export const sendOTP = (dispatch, cookies, mobile) => {
    dispatch(setLoadingStatus({status: true}));  
    const url = `${API_SEND_OTP}?mobile_no=${mobile}`;

    axios.get(url).then((response)=> {
        if(response.status === 200){
            logEvent(ANALYTICS_EVENTS['auth']['send-otp-ack-success']);

            dispatch(setSnackbarMessage({'message': OTP_SUCCESS.OTP_SEND_SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));;
            dispatch(setSendOTPResponse({data:response.data}));
        }else{
            dispatch(setSendOTPResponse({data: {}}));
            dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));             
            logEvent(ANALYTICS_EVENTS['auth']['send-otp-ack-error']);
        }
        dispatch(setLoadingStatus({status: false}));  
    }).catch((error)=> {
        logEvent(ANALYTICS_EVENTS['auth']['send-otp-ack-error']);
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_SEND_OTP , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}

export const verifyOTP = (dispatch, cookies, data) => {
    dispatch(setLoadingStatus({status: true}));  
    const url = API_VERIFY_OTP;

      axios.post(url, data).then((response)=> {
         
        if(response.status === 200){
            const responseData = response.data.data;
            dispatch(setVerifyOTPResponse({data:responseData}));

            if(responseData.success === true){
                logEvent(ANALYTICS_EVENTS['auth']['verify-otp-ack-success']);
                dispatch(setSnackbarMessage({'message': OTP_SUCCESS.OTP_VERIFY_SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));             
            }else{
                logEvent(ANALYTICS_EVENTS['auth']['verify-otp-ack-incorrect']);
                dispatch(setSnackbarMessage({'message': OTP_ERROR.INCORRECT_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));             
    
            }
            dispatch(setLoadingStatus({status: false}));  
        }else{
          logEvent(ANALYTICS_EVENTS['auth']['verify-otp-ack-error']);
          dispatch(setVerifyOTPResponse({data: null}));
          dispatch(setLoadingStatus({status: false}));  
          dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_VERIFY_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
          dispatch(setSnackbarVisibilityStatus({'status': true }));    
        }
      }).catch((error)=> {
        logEvent(ANALYTICS_EVENTS['auth']['verify-otp-ack-error']);
        dispatch(setVerifyOTPResponse({data: null}));
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': OTP_ERROR.UNABLE_TO_VERIFY_OTP, 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));    
      });
}

export const getSession = (dispatch, cookies) => {
  dispatch(setLoadingStatus({status: true}));  
  const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    if(accessToken != null){
        axios({
            method: "get",
            url: API_GET_SESSION,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
          })
          .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setSessionDetails({data: response.data.data}));
                if(response.data.data != null){
                  dispatch(setLoginStatus({data: true}));
                  dispatch(setUserName({data: response.data.data.user_first_name}));
                }
            }else{
              dispatch(setSessionDetails({data: null}));
              dispatch(setLoginStatus({data: false}));
            } 
            dispatch(setLoadingStatus({status: false}));  
            return true;
          })
          .catch((err) => {
            dispatch(setSessionDetails({data: null}));
            dispatch(setLoginStatus({data: false}));
            dispatch(setLoadingStatus({status: false}));  
            // console.log(response);
          });
    }else{
        dispatch(setSessionDetails({data: null}));
        dispatch(setLoginStatus({data: false})); 
        dispatch(setLoadingStatus({status: false}));  
      }
}

export const createSession = (dispatch, cookies, setCookie, accessToken, remember) => {
  dispatch(setLoadingStatus({status: true}));  

  if(accessToken != null){
    const data = {};
    if(remember){
      data.remember_me = true
    }

    axios({
      method: "post",
      url: API_CREATE_SESSION,
      data,
      headers: { "Content-Type": "multipart/form-data",'Authorization': `Bearer ${ accessToken}` },
    })
    .then((response) => {
      if(response.status === 200){
        dispatch(setSessionDetails({data: response.data.data}));
        setCookie('session', response.data.data.session_id, { path: '/' });
        dispatch(setLoginStatus({data: true}));
        dispatch(clearOTPState({data: {}}));
      }else{
        dispatch(setLoginStatus({data: false}));
        dispatch(clearOTPState({data: null}));
      }
      dispatch(setLoadingStatus({status: false}));  
    })
    .catch((response) => {
      dispatch(setLoginStatus({data: false}));
      dispatch(clearOTPState({data: null}));
      dispatch(setLoadingStatus({status: false}));  
    });
  }else{
    dispatch(setLoginStatus({data: false}));
    dispatch(clearOTPState({data: null}));
    dispatch(setLoadingStatus({status: false}));
  }
}

export const generateAccessToken = async (dispatch, cookies, setCookie, userId, clientId, clientSecret, remember) => {
  dispatch(setLoadingStatus({status: true})); 
  const accessTokenUrl = API_GENERATE_ACCESS_TOKEN;

  const bodyFormData = new FormData();
  bodyFormData.append('grant_type', 'password');
  bodyFormData.append('scope', 'user');
  bodyFormData.append('username', userId);
  bodyFormData.append('password', 'valid');

  const username = clientId;
  const password = clientSecret;

  const token = `${username}:${password}`;
  const encodedToken = Buffer.from(token).toString('base64');

  axios({
    method: "post",
    url: accessTokenUrl,
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data",'Authorization': `Basic ${ encodedToken}` },
  })
  .then((accessTokenResponse)  => {
    if(accessTokenResponse.status === 200){
      setCookie("access_token",accessTokenResponse.data.access_token, { path: '/' });
      createSession(dispatch, cookies, setCookie, accessTokenResponse.data.access_token, remember);
      fetchUserDetails(dispatch, cookies);
    }else{
      setCookie("access_token","", { path: '/' });
    }
    dispatch(setLoadingStatus({status: false}));  
  })
  .catch((response) => {
    setCookie("access_token","", { path: '/' });
    dispatch(setLoadingStatus({status: false}));  
  });        
}

export const getAccessTokenFromCookie = (dispatch, cookies) => {

    let accessToken = null; 
    if(cookies.access_token != null && cookies.access_token !== 'null' && cookies.access_token !== ''){
      // if(cookies.session != null && cookies.session !== 'null' && cookies.session !== ''){
        accessToken = cookies.access_token;
        dispatch(setAccessToken({data: accessToken}));
    }

    return accessToken;
};
