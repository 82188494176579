export const OTP_ERROR = {
    INVALID_MOBILE : 'You have entered an invalid mobile number.',
    UNABLE_TO_SEND_OTP : 'Unable to send OTP.',
    INVALID_OTP : 'You have entered an invalid OTP.',
    INCORRECT_OTP : 'Entered OTP is incorrect',
    UNABLE_TO_VERIFY_OTP : 'Unable to verify OTP.',
}; 

export const OTP_SUCCESS = {
    OTP_SEND_SUCCESS : 'OTP sent successfully.',
    OTP_VERIFY_SUCCESS : 'OTP verified successfully.', 
};

export const ORDER_DETAILS_ACK = {
    SUCCESS : 'Order Details fetched successfully.',
    ERROR : 'Unable to fetch order details',
    FAIL : 'Order Details not found'
};

export const CLAIM_ORDER_ACK = {
    SUCCESS : 'Order claimed successfully.',
    FAIL : 'Unable to claim reward'
};

export const MEAL_SUGGESTION_ACK = {
    SUCCESS : 'Meal suggestion fetched successfully.',
    FAIL : 'Unable to fetch meal suggestion'
};

export const MEAL_HISTORY_ACK = {
    SUCCESS : 'Meal histoty fetched successfully.',
    FAIL : 'Unable to fetch meal history'
};

export const WALLET_TRANSACTION_ACK = {
    SUCCESS : 'Wallet transactions fetched successfully.',
    FAIL : 'Unable to fetch wallet transaction'
};

export const WALLET_DETAILS_ACK = {
    SUCCESS : 'Wallet Details fetched successfully.',
    FAIL: 'Unable to fetch wallet details'
};

