import { useEffect, useState, useRef } from 'react';
import { useHistory ,useLocation, Link as RouterLink } from 'react-router-dom';

import { Box, Button, Fab, Grid, SwipeableDrawer, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';

// components
import '../../assets/css/Aggregator.css';
import '../../assets/css/Aggregator2.css';
import WalletTransaction from './WalletTransaction';
import { getWalletDetails } from '../../store/wallet/reducer';
import { fetchWalletDetails } from '../../data/network/wallet';
import { POST_AUTH_ACTION } from '../../constants/app_constants';
import { setLoginFormVisibility, setPostAuthAction } from '../../store/auth/reducer';
import { getLoginStatus } from '../../store/user/reducer';
// ----------------------------------------------------------------------


export default function Wallet() {

  const [visibleSection, setVisibleSection] = useState("Consumption");

  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);

  const location = useLocation();

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const walletDetails = useSelector(state => getWalletDetails(state));

  const isLoggedIn = useSelector(state => getLoginStatus(state));

  useEffect(() => {

    if(!isLoggedIn){
      dispatch(setPostAuthAction({data: [
          POST_AUTH_ACTION.RELOAD_WALLET
      ]}));
      dispatch(setLoginFormVisibility({data: true}));
    }else{
      // fetchOrderHistoryApi(dispatch, cookies);
      fetchWalletDetails(dispatch, cookies);
    }

  },[])


  return (
    
      isLoggedIn
      ? <Grid container justifyContent="center" alignItems="start" alignContent="start" className="" sx={{height:'90vh'}} >
          <Grid container justifyContent="center" alignContent="start" flexDirection="column" className="" sx={{height:'15vh', marginLeft:'5vw'}}>
              <Typography variant="span">Available Credits</Typography>
              <Typography variant="h3" sx={{color:'green'}}>{walletDetails && walletDetails.balance}</Typography>
          </Grid>
          <Grid container justifyContent="start" alignContent="start" alignItems="start" className="" sx={{height:'75vh', backgroundColor:'#fff', borderRadius:'20px 20px 0 0', overflow:'scroll'}} >
              <WalletTransaction />
          </Grid>
      </Grid>
      : <Grid container flexDirection="column" justifyContent="center" alignItems="center" alignContent="center" className="" sx={{height:'70vh'}} >
          
          <Typography variant="span">Please login to continue</Typography>
          <Box height={10}></Box>
          <Button variant="outlined" onClick={() => {
                dispatch(setLoginFormVisibility({data: true}));
            }}>
                Login
          </Button>
      </Grid>
    
);

}