import { useState } from 'react';
// @mui
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack,List, ListItem, ListItemText, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import '../../assets/css/Aggregator.css';
import Iconify from '../../components/Iconify';


const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '50vh',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: theme.spacing(0, 0),
  }));
  
// ----------------------------------------------------------------------

export default function SubscriptionSection() {

    const [currentSlide, setCurrentSlide] = useState(0);


    const [touchStart, setTouchStart] = useState(null)
    const [touchEnd, setTouchEnd] = useState(null)

    const SLIDE_LENGTH = 3;

    const SLIDES = [
        {
            'title' : 'What we do?',
            'content' : 'We are a bunch of passionate people busy building a new age food-tech startup where our food and technology is based on research and data. While the kitchen team is busy in daily R&D to curate new dishes, create the best taste and implement new nutrition preservation techniques, the technology team is continuously building multiple products to optimize the productivity, cost, storage, wastage control and visibility in our kitchen and outlets. ',
            'component': <></>
        },
        {
            'title': 'How are we different?',
            'content': 'Our technology does not end with a successful delivery, rather it starts from there. Our machine learning algorithms help us in analysing nutrition requirements as well as recommending best suited food and lifestyle suggestions to our consumers. We are relentless in making these systems more accurate as we grow with data and create a convenient food ecosystem for our users.',
            'component': <></>
        },
        {
            'title': 'Subscribe Now',
            'content': 'Our professional R&D and nutrition team is continuously curating new dishes to ensure we don’t compromise on taste and quality of the food. Our meal subscription plans give users visibility of their upcoming meal according to their preferences. A subscribed user can customise their upcoming meal from a wide range of new dishes. Users can also play and pause their subscriptions.',
            'component': <Grid container justifyContent="center" ><Box sx={{width:'60%'}}><Button fullWidth variant="contained" onClick={() => {
                window.open("https://the-bytes.com", "_blank");
            }}>Visit</Button></Box></Grid>
        }
    ]

    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50 

    const onTouchStart = (e) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance
        if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
        
        if(isLeftSwipe){
            if(currentSlide < SLIDE_LENGTH-1){
                setCurrentSlide(currentSlide+1);
            }
        }else{
            if(currentSlide > 0){
                setCurrentSlide(currentSlide-1);
            }
        }
    }

    return (
        <Container id="subscription_section" className="subscription-detail" maxWidth="lg">
            <ContentStyle>

                <Typography variant="h4" paddingLeft={2}>About Us</Typography>
                {/* <Grid item container justifyContent="space-between">
                    <Grid item>
                        <Typography variant="h3">About Us</Typography>
                    </Grid>
                    <Grid item>
                        {
                            currentSlide > 0
                            ? <Button className="nav-button" size="large" padding={2} variant="text" onClick={() => {
                                setCurrentSlide(currentSlide-1);
                            }}>
                                <Iconify icon="eva:arrow-ios-back-outline"></Iconify>
                            </Button>
                            : <Button  className="nav-button" size="large" padding={2} disabled variant="text"><Iconify icon="eva:arrow-ios-back-outline"></Iconify></Button>
                        }
                        {
                            currentSlide < SLIDE_LENGTH-1
                            ? <Button className="nav-button" size="large" variant="text" onClick={() => {
                                setCurrentSlide(currentSlide+1);
                            }}>
                                <Iconify icon="eva:arrow-ios-forward-outline"></Iconify>
                            </Button>
                            : <Button  className="nav-button" size="large" padding={2} disabled variant="text"><Iconify icon="eva:arrow-ios-forward-outline"></Iconify></Button>
                        }
                    </Grid>
                </Grid> */}

                <Box>
                    <List className="about-us-list" component={Stack} direction="row">
                        {
                            SLIDES.map((e, i) => (
                                <ListItem key={i}>
                                    <ListItemText>
                                        <Card className="glass-box" sx={{height:'60vh', padding:'10px', overflowY:'scroll'}} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
                                            <Typography variant="h5">{e.title}</Typography>
                                            <Box margin={2}></Box>
                                            <Typography variant="p">{e.content}</Typography>
                                            <Box margin={4}></Box>
                                            {e.component}
                                        </Card>
                                    </ListItemText>
                                </ListItem>
                            ))
                        }

                    </List>

                </Box>
            </ContentStyle>
        </Container>
    );
}
