import { Grid,Card, Typography, Box, List, ListItem, ListItemText, Stack } from "@mui/material";
import { useState } from "react";

import '../../assets/css/OrderItemCard.css';
import Iconify from "../Iconify";

export default function OrderItemCard(props) {
    const {dish, key} = props;

    // let img_url = "https://images.unsplash.com/photo-1565299624946-b28f40a0ae38?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Zm9vZHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
    let img_url = "/static/images/placeholder/dish.png";
    if(dish.image_url && dish.image_url !== null){
        img_url = dish.image_url;
    }

    const nutrition_info = []
    let calorie_count = null;
    let dish_preference = 'vegetarian';
    let dish_color = 'green';

    if(dish.dish_nutrition_info){

        dish.dish_nutrition_info.forEach(element => {
            if(element.name.toLowerCase() === 'calorie'){
                calorie_count = element.value + " " + element.primary_unit_display_name;
            }else{
                nutrition_info.push(element)
            }
        });
    }

    if(dish.is_non_veg){
        dish_preference = 'non_vegetarian';
        dish_color = 'darkred';
    }

    // if(dish.tags){
    //     dish.tags.forEach(element => {
    //         if(element.name === 'preference'){
    //             if(element.value.indexOf('eggetarian') !== -1){
    //                 dish_preference = 'eggetarian';
    //                 dish_color = 'yellow';
    //             }
    //             if(element.value.indexOf('non_vegetarian') !== -1){
    //                 dish_preference = 'non_vegetarian';
    //                 dish_color = 'red';
    //             }
    //         }
    //     });
    // }

    return (
        <Grid key={key} item className="w-100" mb={2}>
            <Card className="glass-box w-100 order-item">
                <Grid container direction="row">
                    <Grid item className="img-container">
                        <img width={140} height={140} src={img_url} alt={dish.display_name} />
                    </Grid>
                    <Grid item padding={0} className='detail-container'>
                        <Typography variant="h6" marginLeft={1.5} >
                            {dish.display_name} {`( x${dish.qty} )`}
                        </Typography>

                        <Grid item container direction="row" justifyContent="space-between" padding={0} alignContent="center">

                            <Grid item justifyContent="start" padding={1} alignContent="center">
                                <span style={{textAlign:'center', alignItems:'center', justifyContent:'center'}}>
                                    {
                                        <Iconify icon="fa:circle" sx={{color: dish_color, marginTop:'1px'}}></Iconify>
                                    }
                                </span>
                                <span style={{paddingLeft:'6px',fontSize:'0.8rem'}}>
                                    {
                                        dish_preference === 'eggetarian'
                                        ? "Contains Egg"
                                        : (dish_preference === 'non_vegetarian'
                                            ? "Non-Veg"
                                            : "Veg"
                                        )
                                    }
                                </span>

                            </Grid>

                            <Grid item justifyContent="start" padding={1} alignContent="center">
                                <Iconify icon="eva:flash-outline"></Iconify>
                                <Typography variant="p" className='calorie-count' sx={{fontSize:'0.8rem'}}>{calorie_count}</Typography>
                            </Grid>

                        </Grid>
                         
                        <List className="nutrition-detail" component={Stack} direction="row">
                            {
                                nutrition_info.length > 0 &&
                                nutrition_info.map((n,k) => (                                    
                                    <ListItem key={k}>
                                        <ListItemText>
                                            <Box>
                                                <Typography className="nutrition-value" variant="h7">
                                                    {parseFloat(n.value).toFixed(2)} 
                                                    {/* {n.primary_unit_display_name} */}
                                                </Typography>
                                                <Typography className="nutrition-label" >
                                                    {n.display_name}
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>      
                                ))
                            }
                        </List>
                        <Grid item container justifyContent="flex-end">
                            <Typography className="nutrition-help-text">All units are in gms</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
}