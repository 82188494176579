import axios from '../../utils/axios-configure';

import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import { SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';
import { API_GET_WALLET, API_GET_ORDER_DETAILS, API_CLAIM_ORDER, API_GET_MEAL_SUGGESTION, API_GET_ORDER_HISTORY, API_GET_WALLET_TRANSACTION} from "./endpoints";
import { ORDER_DETAILS_ACK, CLAIM_ORDER_ACK, MEAL_SUGGESTION_ACK, MEAL_HISTORY_ACK, WALLET_TRANSACTION_ACK, WALLET_DETAILS_ACK } from '../../constants/message_constants';
import { getAccessTokenFromCookie } from './login';
import { ANALYTICS_EVENTS } from '../../constants/analytics_events';
import { logEvent } from '../../utils/analytics';
import { setWalletTransaction, setWalletDetails, setWalletTransactionPagination, appendWalletTransaction } from '../../store/wallet/reducer';

export const fetchWalletDetails = (dispatch, cookies) => {

    const accessToken = getAccessTokenFromCookie(dispatch, cookies);

    // dispatch(setLoadingStatus({status: true}));
    axios({
        method: "get",
        url: `${API_GET_WALLET}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    }).then((response)=> {
        if(response.status === 200){
            const responseData = response.data;
            dispatch(setWalletDetails({data:response.data.data}));
        }else{
            dispatch(setSnackbarMessage({'message': WALLET_DETAILS_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setWalletDetails({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));
    }).catch((error)=> {
        dispatch(setWalletDetails({data: null}));
        dispatch(setLoadingStatus({status: false}));
        dispatch(setSnackbarMessage({'message': WALLET_DETAILS_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}

export const fetchWalletTransaction = async (dispatch, cookies, page) => {

    const accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({status: true}));

    axios({
        method: "get",
        url: `${API_GET_WALLET_TRANSACTION}?count=7&page=${page}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    }).then((response)=> {
        if(response.status === 200){
            const responseData = response.data;
            if(page === 1){
                dispatch(setWalletTransaction({data:response.data.data}));
            }else{
                dispatch(appendWalletTransaction({data:response.data.data}));
            }
            dispatch(setWalletTransactionPagination({data:response.data.pagination}));
        }else{
            dispatch(setSnackbarMessage({'message': WALLET_TRANSACTION_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setWalletTransaction({data: null}));
            dispatch(setWalletTransactionPagination({data:null}));
        }
        dispatch(setLoadingStatus({status: false}));
    }).catch((error)=> {
        dispatch(setWalletTransaction({data: null}));
        dispatch(setLoadingStatus({status: false}));
        dispatch(setSnackbarMessage({'message': WALLET_TRANSACTION_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
        dispatch(setWalletTransactionPagination({data:null}));
    });
}