import { useEffect, useState, useRef } from 'react';
import { useHistory ,useLocation, Link as RouterLink } from 'react-router-dom';

// @mui
import { styled } from '@mui/material/styles';
import { Box, Button, Fab, Grid, SwipeableDrawer, Typography } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga';

// hooks
import useResponsive from '../hooks/useResponsive';

// components
import Page from '../components/Page';
import Logo from '../components/Logo';

import '../assets/css/Aggregator.css';
import { HEADER_TABS, STATUS } from '../constants/app_constants';
import ConsumptionSection from '../sections/dashboard/ConsumptionSection';
import MealSection from '../sections/dashboard/MealSection';
import SubscriptionSection from '../sections/dashboard/SubscriptionSection';
import Footer from '../components/Footer';

import '../assets/css/Aggregator2.css';
import { fetchMealSuggestionApi, fetchOrderDetailsApi } from '../data/network/order';
import { getOrderDetails, getOrderFetchStatus, getOrderId, getSuggestedMeals, setOrderFetchStatus, setOrderId } from '../store/order/reducer';
import LoginForm from '../sections/login/LoginForm';
import { getLoginStatus } from '../store/user/reducer';
import Iconify from '../components/Iconify';
import { getLoginFormVisibility, setLoginFormVisibility } from '../store/auth/reducer';
import SideMenu from '../components/menu/SideMenu';
import NotFound from '../sections/404/NotFound';
import { getLoadingStatus } from '../store/layout/reducer';
import { logEvent } from '../utils/analytics';
import { ANALYTICS_EVENTS } from '../constants/analytics_events';
// ----------------------------------------------------------------------



const getDimensions = ele => {
  try{
    const { height } = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  }catch(e){
    console.log(e);

    return {
      height: 0,
      offsetTop: 0,
      offsetBottom : 0
    }
  }
};

const scrollTo = ele => {
  ele.scrollIntoView({
    behavior: "smooth",
    block: "start",
  });
};

export default function AggregatorMeal() {

  const [visibleSection, setVisibleSection] = useState("Consumption");

  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState(false);


  const headerRef = useRef(null);
  const consumptionRef = useRef(null);
  const mealRef = useRef(null);
  const subscriptionRef = useRef(null);

  const sectionRefs = [
    { section: "Consumption", ref: consumptionRef },
    { section: "Meal", ref: mealRef },
    { section: "Subscription", ref: subscriptionRef },
  ];

  const location = useLocation();

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const orderId = useSelector(state => getOrderId(state));
  const orderDetails = useSelector(state => getOrderDetails(state));
  const isLoggedIn = useSelector(state => getLoginStatus(state));
  const suggestedMeals = useSelector(state => getSuggestedMeals(state));
  const orderFetchStatus = useSelector(state => getOrderFetchStatus(state));
  // const isLoading = useSelector(state => getLoadingStatus(state));
  
  useEffect(() => {
    const loc_arr = location.pathname.split('/');
    dispatch(setOrderFetchStatus({status : STATUS.LOADING}));
    try{
      var orderIdParam = loc_arr[2];

      logEvent(ANALYTICS_EVENTS['page-view']['order-details']);
    
      if(orderIdParam && orderIdParam !== ""){
        // store order id in state
        dispatch(setOrderId({data: orderIdParam}));
      }else{
        // show login popup
        if(!isLoggedIn){
          dispatch(setLoginFormVisibility({data: true}));
        }
      }
    }catch(e){
      // show login popup
      if(!isLoggedIn){
        dispatch(setLoginFormVisibility({data: true}));
      }
    }
  },[location])

  useEffect(() => {
    if(orderId){
      // fetch Order Details
      fetchOrderDetailsApi(dispatch, cookies, orderId);

      // fetch suggested meals
      fetchMealSuggestionApi(dispatch, cookies, orderId);
    }
  },[orderId])

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight + 100;
 
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [visibleSection]);

  return (
    <div>
      {
        orderFetchStatus && orderFetchStatus !== null && orderFetchStatus === STATUS.DONE 
        ? (
          orderDetails && orderDetails !== null
          ? <div className="content">
            <div className="sticky">
              <div className="header" style={{borderRadius:0}} ref={headerRef}>
                {
                  orderDetails && orderDetails.nutrition_info && orderDetails.nutrition_info.length>0
                  && <button
                    type="button"
                    className={`header_link ${visibleSection === "Consumption" ? "selected" : ""}`}
                    onClick={() => { 
                      scrollTo(consumptionRef.current);
                    }}
                  >
                    Consumption
                  </button>
                } 
                {
                  suggestedMeals && suggestedMeals !== null && 
                  <button
                    type="button"
                    className={`header_link ${visibleSection === "Meal" ? "selected" : ""}`}
                    onClick={() => {
                      scrollTo(mealRef.current);
                    }}
                  >
                    Meal
                  </button>
                }

                <button
                  type="button"
                  className={`header_link ${visibleSection === "Subscription" ? "selected" : ""}`}
                  onClick={() => {
                    scrollTo(subscriptionRef.current);
                  }}
                >
                  Subscription
                </button>
              </div>
            </div>
            <div className="section" id="Consumption" ref={consumptionRef}>
              <ConsumptionSection />
              <Box margin={2}></Box>

            </div>
            <div className="section" id="Meal" ref={mealRef}>
              <MealSection />
            </div>
            <div className="section" id="Subscription" ref={subscriptionRef}>
              <SubscriptionSection />
              <center>
                <a href='https://play.google.com/store/apps/details?id=in.thebytes.app&utm_source=aggregator-app&utm_campaign=aggregator-user-onboarding-campaign&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                  <img height="100" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
              </center>
            </div>
          </div>
          : <>
            <NotFound />
          </>
        )
        : <></>
      }
    </div>
  );
}
