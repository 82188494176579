import TagManager from 'react-gtm-module'
import { tagManagerArgs } from '../constants/analytics_events';

// {'category': '', 'label':''}
export function logEvent(data){

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: data.event,
        event_name: data.event,
        category: data.category,
        label: data.label
  })

    // console.log(data);
    // const args = tagManagerArgs;

    // args['events'] = {
    //     dataLayer: {
    //       'event': "custom_event",
    //       'event_name': data.event,
    //       'category': data.category,
    //       'label': data.label
    //       /* can pass more variables here if needed */
    //     },
    //     dataLayerName: "PageDataLayer"
    // };
    // TagManager.dataLayer(args);
}
