import { useEffect, useState } from 'react';
import * as Yup from 'yup';

// @mui
import { TextField, Container, Typography, Box, Tabs, Tab, Grid, Stack, Link, breadcrumbsClasses} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { Form, FormikProvider, useFormik } from 'formik';
import {Link as RouterLink} from 'react-router-dom';

import '../../assets/css/LoginForm.css';
import { LoadingButton } from '@mui/lab';
import { clearOTPState, getClientId, getClientSecret, getOTPSentStatus, getOTPVerificationStatus, getPostAuthAction, getUserId, setPostAuthAction } from '../../store/auth/reducer';
import { generateAccessToken, sendOTP, verifyOTP } from '../../data/network/login';
import { getLoginStatus, getUser } from '../../store/user/reducer';
import { fetchUserDetails } from '../../data/network/user';
import { POST_AUTH_ACTION } from '../../constants/app_constants';
import { getOrderId } from '../../store/order/reducer';
import { setLoadingStatus } from '../../store/layout/reducer';
import { claimOrderApi } from '../../data/network/order';
import { logEvent } from '../../utils/analytics';
import { ANALYTICS_EVENTS } from '../../constants/analytics_events';
import { fetchWalletTransaction } from '../../data/network/wallet';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: theme.spacing(4, 0),
}));
  
// ----------------------------------------------------------------------

export default function LoginForm({handleClose}) {

    const dispatch = useDispatch();
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const [userMobile, setUserMobile] = useState(null);
    const [otpValue, setOTPValue] = useState(null);
    const [showOTPContainer, setShowOTPContainer] = useState(false);
    const otpSent = useSelector((state) => getOTPSentStatus(state));
    const otpVerified = useSelector((state) => getOTPVerificationStatus(state)); 
    const userId = useSelector((state) => getUserId(state));
    const clientId = useSelector((state) => getClientId(state));
    const clientSecret = useSelector((state) => getClientSecret(state));
    const isLoggedIn = useSelector((state) => getLoginStatus(state));
    const orderId = useSelector((state) => getOrderId(state));
    const postAuthAction = useSelector((state) => getPostAuthAction(state));

    useEffect(() => {
        if(otpSent){
            setShowOTPContainer(true);
        }else{
            setShowOTPContainer(false);
        }
    },[otpSent]);

    useEffect(() => {
        if(otpVerified){
            generateAccessToken(dispatch, cookies, setCookie, userId, clientId, clientSecret, true);
        }
    },[otpVerified]);

    useEffect(() => {
        if(isLoggedIn){

            fetchUserDetails(dispatch, cookies, userId).then(() => {
 
                dispatch(clearOTPState());
                handleClose();
                runPostAuthAction();    
            });
        }
    },[isLoggedIn]);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
 
    const LoginSchema = Yup.object().shape({
      mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
      .min(10, "Phone number is too short")
      .max(10, "Phone number is too long"),
      // email('Please enter valid mobile number').required('Mobile number is required'),
      otp: Yup.string()
        .required('OTP is required')
        .min(4, "OTP is too short")
        .max(4, "OTP is too long"),
    });

    const formik = useFormik({
        initialValues: {
            mobile: '',
            otp: '', 
        },
        validationSchema: LoginSchema,
        onSubmit: () => {

        },
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    const handleSendOTPClick = () => {
        logEvent(ANALYTICS_EVENTS['auth']['send-otp-clicked']);
        dispatch(clearOTPState());

        if(userMobile !== null){
            // call send OTP Api here
            sendOTP(dispatch, cookies, userMobile);
        }
    }

    const handleVerifyOTPClick = () => { 
        logEvent(ANALYTICS_EVENTS['auth']['verify-otp-clicked']);
        // call verify OTP Api here 
        verifyOTP(dispatch, cookies, {
            'mobile_no': userMobile,
            'otp': otpValue
        });
    }

    const runPostAuthAction = async () => { 
        if(postAuthAction && postAuthAction !== null && postAuthAction.length > 0) {
            dispatch(setLoadingStatus({status: true}));
            await postAuthAction.forEach(async element => {
                switch(element){
                    case POST_AUTH_ACTION.CLAIM_ORDER:
                        await claimOrderApi(dispatch, cookies, {
                            'order_id' : orderId
                        });
                        break;
                    case POST_AUTH_ACTION.RELOAD_WALLET:
                        await fetchWalletTransaction(dispatch, cookies, 1);
                        break;
                    default:
                        break;
                }
            });

            dispatch(setPostAuthAction({data: []}));
            dispatch(setLoadingStatus({status: false}));
            // window.location.reload();
        }
    }
    
    return (
        <Container id="login_form" maxWidth="lg" className="login-form">
            <ContentStyle>
                <Box>
                    <Grid item container justifyContent="center" direction="column">
                        <Typography variant="h4" mb={2} sx={{textAlign:'center'}}>Welcome to Bytes</Typography>
                        <Stack spacing={3} justifyContent="center" alignItems="center">
                            <TextField
                                className="glass-box"
                                autoComplete="off"
                                type="number"
                                label="Mobile Number"
                                onKeyUp={e => {
                                    setUserMobile(e.target.value)
                                }}
                                {...getFieldProps('mobile')}
                                error={Boolean(touched.mobile && errors.mobile)}
                                helperText={touched.mobile && errors.mobile}
                                style={{maxWidth:'800px'}}
                            />
                            {
                            showOTPContainer  
                            ? <TextField
                                className='glass-box'
                                autoComplete="current-otp"
                                type="number"
                                label="OTP"
                                onKeyUp={e => {
                                    setOTPValue(e.target.value);
                                }}
                                {...getFieldProps('otp')}
                                error={Boolean(touched.otp && errors.otp)}
                                helperText={touched.otp && errors.otp}
                                style={{maxWidth:'500px'}}
                            />
                            : <Container />  }                            
                        </Stack>

                        <Grid direction="column" justifyContent="center" alignItems="center" container>
                            <Box padding={2}>
                                { !showOTPContainer 
                                    ? <LoadingButton fullWidth size="large" onClick={() => handleSendOTPClick()} variant="outlined" loading={isSubmitting}>
                                            Send OTP
                                        </LoadingButton>                                    
                                    : <></>  
                                }

                                { showOTPContainer
                                    ? <LoadingButton  fullWidth size="large"  onClick={() => handleVerifyOTPClick()} variant="outlined" loading={isSubmitting}>
                                        Verify OTP
                                    </LoadingButton> 
                                    : <></>
                                }
                            </Box>
                        </Grid>

                        { showOTPContainer  ? 
                            <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2 }}>                            
                                <Link component={RouterLink} variant="subtitle2" to="#" underline="hover" onClick={() => handleSendOTPClick()}>
                                    Resend OTP?
                                </Link>
                            </Stack>
                            : <Container />  
                        }
                    </Grid>
                </Box>
            </ContentStyle>
        </Container>
    );
}
