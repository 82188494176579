import { createSlice } from '@reduxjs/toolkit';
import { mealFilters } from '../../constants/app_constants';

const mealSlice = createSlice({
  name: 'meal',
  initialState: {
    mealList: [],
    mealFilters : []
  },
  reducers: {
    setMealList(state, action) {
        state.mealList = action.payload.data;
    },
    addMealFilter(state, action) {
        if(action.payload.data !== null){

            // action.payload.data   
            // {
            //     'key' : 'cuisine',
            //     'value' : 'Fusion'
            // }

            const mealFilters = state.mealFilters;

            let found = false;
            for(let i = 0; i < mealFilters.length; i++) {
                if(mealFilters[i].key === action.payload.data.key){
                    found = true;
                    if(mealFilters[i].value.indexOf(action.payload.data.value) === -1){
                        mealFilters[i].value.push(action.payload.data.value);
                    }
                }
            }

            if(!found){
                mealFilters.push({
                    'key' : action.payload.data.key,
                    'value': [action.payload.data.value]
                })
            }

            state.mealFilters = mealFilters;
        }else{
            // state.mealFilters = [];
        }
    },
    removeMealFilter(state, action) {
        // action.payload.data   
        // {
        //     'key' : 'cuisine',
        //     'value' : 'Fusion'
        // }

        if(action.payload.data !== null){
            const mealFilters = state.mealFilters;

            for(let i = 0; i < mealFilters.length; i++) {
                if(mealFilters[i].key === action.payload.data.key){
                    let value_index = mealFilters[i].value.indexOf(action.payload.data.value)
                    if(value_index !== -1){
                        mealFilters[i].value.splice(value_index, 1);
                    }
                }
            }
            state.mealFilters = mealFilters;
        }else{
            // state.mealFilters = [];
        }  
    },
    resetMealState(state, action){
      state.mealList = [];
    }, 
    resetMealFilters(state, action){
        state.mealFilters = [];
      }, 
    }
})

export const getMealList = (state) => {

    try{
        return state.meal.mealList;
    }catch(e){
        return [];
    } 
}

export const getMealFilter = (state) => {
    try{
        return state.meal.mealFilters;
    }catch(e){
        return [];
    } 
}

export const getMealFilterValues = (state, filterType='all') => {
    try{
        const data = [];

        state.meal.mealFilters.forEach(element => {
            if(filterType === 'all' || element.key === filterType){
                if(element.value && element.value !== null){
                    element.value.forEach(item => {
                        data.push(
                            {
                                'key' : element.key,
                                'value' : item
                            }
                        )
                    });
                }
            }
        });

        return data;
    }catch(e){
        return [];
    } 
}

export const { setMealList, setMealFilters, addMealFilter, removeMealFilter } = mealSlice.actions

export default mealSlice.reducer