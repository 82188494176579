import { Navigate, useRoutes } from 'react-router-dom';
import NotFound from './pages/Page404';
import AggregatorMeal from './pages/AggregatorMeal';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import NavHeaderLayout from './layouts/NavHeaderLayout';
import OrderHistory from './pages/OrderHistory';
import Wallet from './pages/wallet/Wallet';
import Home from './pages/Home';

export default function Router() {
    return useRoutes([
      {
        path: '/order',
        element: <NavHeaderLayout />,
        children: [
          { path: '', element: <NotFound /> },
          { path: 'history', element: <OrderHistory /> },
          { path: ':id', element: <AggregatorMeal /> },
        ],
      },
      {
        path: '/wallet',
        element: <NavHeaderLayout />,
        children: [
          { path: '', element: <Wallet /> },
        ],
      },
      // {
      //   path: '/user',
      //   element: <LogoOnlyLayout />,
      //   children: [
      //     { path: 'profile', element: <NotFound /> },
      //   ],
      // },
      // {
      //   path: '/reward',
      //   element: <LogoOnlyLayout />,
      //   children: [
      //     { path: '', element: <NotFound /> },
      //     { path: ':id', element: <AggregatorMeal /> },
      //   ],
      // },
      {
        path: '/',
        element: <NavHeaderLayout />,
        children: [
            { path: '', element: <Home /> },
            { path: '404', element: <NotFound /> },
        ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
    ]);
}