import axios from '../../utils/axios-configure';

import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import { SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';
import { API_GET_ORDER_DETAILS, API_CLAIM_ORDER, API_GET_MEAL_SUGGESTION, API_GET_ORDER_HISTORY} from "./endpoints";
import { ORDER_DETAILS_ACK, CLAIM_ORDER_ACK, MEAL_SUGGESTION_ACK, MEAL_HISTORY_ACK } from '../../constants/message_constants';
import { setOrderClaimResponse, setOrderDetails, setOrderFetchStatus, setOrderHistory, setSuggestedMeals } from '../../store/order/reducer';
import { getAccessTokenFromCookie } from './login';
import { ANALYTICS_EVENTS } from '../../constants/analytics_events';
import { logEvent } from '../../utils/analytics';

export const fetchOrderDetailsApi = (dispatch, cookies, orderId) => {
    dispatch(setLoadingStatus({status: true}));
    const url = `${API_GET_ORDER_DETAILS}?order_id=${orderId}`;

    axios.get(url).then((response)=> {
        if(response.status === 200){
            logEvent(ANALYTICS_EVENTS['order-details']['order-details-ack-success']);
            const responseData = response.data;
            // dispatch(setSnackbarMessage({'message': ORDER_DETAILS_ACK.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setOrderDetails({data:response.data.data}));
        }else{
            logEvent(ANALYTICS_EVENTS['order-details']['order-details-ack-error']);
            // dispatch(setSnackbarMessage({'message': ORDER_DETAILS_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));             
            dispatch(setOrderDetails({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));
    }).catch((error)=> {
        logEvent(ANALYTICS_EVENTS['order-details']['order-details-ack-error']);
        dispatch(setOrderDetails({data: null}));
        dispatch(setLoadingStatus({status: false}));  
        // dispatch(setSnackbarMessage({'message': ORDER_DETAILS_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
        // dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}

export const claimOrderApi = async (dispatch, cookies, data) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies);
    dispatch(setLoadingStatus({status: true}));
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_CLAIM_ORDER}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response)=> {
            console.log(response.status);
            if(response.status === 200){
                const responseData = response.data;
                // if(responseData.success){
                logEvent(ANALYTICS_EVENTS['claim-order']['claim-order-ack-success']);
                dispatch(setSnackbarMessage({'message': CLAIM_ORDER_ACK.SUCCESS, 'severity': SNACKBAR_SEVERITY.SUCCESS}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));
                dispatch(setOrderClaimResponse({data:responseData}));

                // dispatch(fetchOrderDetailsApi(dispatch, cookies, data['order_id']));
                // }else{
                //     dispatch(setSnackbarMessage({'message': CLAIM_ORDER_ACK.FAIL, 'severity': SNACKBAR_SEVERITY.ERROR}));
                //     dispatch(setSnackbarVisibilityStatus({'status': true }));             
                //     dispatch(setOrderClaimResponse({data: null}));
                // }
            }else{
                logEvent(ANALYTICS_EVENTS['claim-order']['claim-order-ack-error']);
                dispatch(setSnackbarMessage({'message': CLAIM_ORDER_ACK.FAIL, 'severity': SNACKBAR_SEVERITY.ERROR}));
                dispatch(setSnackbarVisibilityStatus({'status': true }));             
                dispatch(setOrderClaimResponse({data: null}));
            }
            dispatch(setLoadingStatus({status: false}));

        }).catch((error)=> {
            console.log(error);
            logEvent(ANALYTICS_EVENTS['claim-order']['claim-order-ack-error']);
            dispatch(setOrderClaimResponse({data: null}));
            dispatch(setSnackbarMessage({'message': CLAIM_ORDER_ACK.FAIL, 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true })); 
            dispatch(setLoadingStatus({status: false}));  
        });
    }

    dispatch(setLoadingStatus({status: false}));  
}

export const fetchMealSuggestionApi = (dispatch, cookies, orderId) => {

    dispatch(setLoadingStatus({status: true}));
    const url = `${API_GET_MEAL_SUGGESTION}?order_id=${orderId}`;

    axios.get(url).then((response)=> {
        if(response.status === 200){
            const responseData = response.data;
            logEvent(ANALYTICS_EVENTS['meal-suggestion']['meal-suggestion-ack-success']);

            // dispatch(setSnackbarMessage({'message': MEAL_SUGGESTION_ACK.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setSuggestedMeals({data:response.data.data}));
        }else{
            logEvent(ANALYTICS_EVENTS['meal-suggestion']['meal-suggestion-ack-error']);
            // dispatch(setSnackbarMessage({'message': MEAL_SUGGESTION_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setSuggestedMeals({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));
    }).catch((error)=> {
        logEvent(ANALYTICS_EVENTS['meal-suggestion']['meal-suggestion-ack-error']);
        dispatch(setSuggestedMeals({data: null}));
        dispatch(setLoadingStatus({status: false}));  
        // dispatch(setSnackbarMessage({'message': MEAL_SUGGESTION_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
        // dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}

export const fetchOrderHistoryApi = (dispatch, cookies) => {

    dispatch(setLoadingStatus({status: true}));
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    
    axios({
        method: "get",
        url: `${API_GET_ORDER_HISTORY}`,
        headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
    }).then((response)=> {
        if(response.status === 200){
            const responseData = response.data;
            // dispatch(setSnackbarMessage({'message': MEAL_SUGGESTION_ACK.SUCCESS , 'severity': SNACKBAR_SEVERITY.SUCCESS}));
            // dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setOrderHistory({data:response.data.data}));
        }else{
            dispatch(setSnackbarMessage({'message': MEAL_HISTORY_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
            dispatch(setSnackbarVisibilityStatus({'status': true }));
            dispatch(setOrderHistory({data: null}));
        }
        dispatch(setLoadingStatus({status: false}));
    }).catch((error)=> {
        dispatch(setOrderHistory({data: null}));
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setSnackbarMessage({'message': MEAL_HISTORY_ACK.FAIL , 'severity': SNACKBAR_SEVERITY.ERROR}));
        dispatch(setSnackbarVisibilityStatus({'status': true }));             
    });
}