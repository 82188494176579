import { Copyright, CopyrightRounded } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import React from "react";
import { FOOTER_LINKS } from "../constants/app_constants";
import {
  Box,
  Column,
  FooterLink,
} from "./FooterStyles";
import Iconify from "./Iconify";
  
const Footer = () => (
    <Box className=" " >
      <Grid container direction={{ "sm" : "row", "xs" : "column" }} justifyContent="center" 
         >
        <Grid item container mt={2}
          xs={12} sm={6} md={4} lg={3} xl={3} justifyContent="center" >
          <Column>
            <FooterLink href={FOOTER_LINKS.ABOUT_US} target="_blank">About Us</FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.PRIVACY_POLICY} target="_blank">Privacy Policy</FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.FAQS} target="_blank">FAQs </FooterLink>
          </Column>            
        </Grid>
        <Grid item container mt={2} justifyContent="center" 
          xs={12} sm={6} md={4} lg={6} xl={6}>
          <Typography style={{fontSize: '0.8rem', fontWeight:'600'}}><CopyrightRounded style={{fontSize: '0.8rem'}} /> 2022 ByteRight Technologies Pvt Ltd. </Typography>
        </Grid>
        <Grid item container mt={2} justifyContent="center" 
          xs={6} sm={6} md={4} lg={3} xl={3}>
          <Column> 
            <FooterLink href={FOOTER_LINKS.FACEBOOK_URL} target="_blank">
              <Iconify icon="fa:facebook" />
            </FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.INSTAGRAM_URL} target="_blank">
              <Iconify icon="fa:instagram" />
            </FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.TWITTER_URL} target="_blank">
              <Iconify icon="fa:twitter" />
            </FooterLink>
          </Column>            
          <Column> 
            <FooterLink href={FOOTER_LINKS.LINKEDIN_URL} target="_blank">
              <Iconify icon="fa:linkedin" />
            </FooterLink>
          </Column>            
          <Column> 
            <FooterLink href={FOOTER_LINKS.YOUTUBE_URL} target="_blank">
              <Iconify icon="fa:youtube" />
            </FooterLink>
          </Column>
          <Column> 
            <FooterLink href={FOOTER_LINKS.PLAY_STORE_URL} target="_blank">
              <Iconify icon="mdi:google-play" />
            </FooterLink>
          </Column>            
        </Grid>
      </Grid>
    </Box>
  );
export default Footer;