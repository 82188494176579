import { createSlice } from '@reduxjs/toolkit'

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loginFormVisible: false,
    sendOTPResponse: null,
    verifyOTPResponse: null,
    postAuthAction: []
  },
  reducers: {
    setLoginFormVisibility(state, action) {
      state.loginFormVisible = action.payload.data;
    },
    setSendOTPResponse(state, action) {
      state.sendOTPResponse = action.payload.data;
    },
    setVerifyOTPResponse(state, action) {
      state.verifyOTPResponse = action.payload.data;
    },
    setPostAuthAction(state, action) {
      state.postAuthAction = action.payload.data;
    },
    clearOTPState(state,action){
      state.sendOTPResponse = null;
      state.verifyOTPResponse = null;
    }
  }
})

export const getLoginFormVisibility = (state) => {
  try {
    return state.auth.loginFormVisible;
  } catch (error) {
    return null;
  }
}

export const getPostAuthAction = (state) => {
  try {
    return state.auth.postAuthAction;
  } catch (error) {
    return [];
  }
}

export const getClientId = (state) => {
  try {
    return state.auth.verifyOTPResponse.client_id;
  } catch (error) {
    return null;
  }
}

export const getClientSecret = (state) => {
  try {
    return state.auth.verifyOTPResponse.client_secret;    
  } catch (error) {
    return null;
  }
}

export const getUserId = (state) => {
    try {
      return state.auth.verifyOTPResponse.user.id;
    } catch (error) {
      return null;
    }
}
  
export const getSendOTPResponse = (state) => {
  if(state.auth != null && state.auth !== undefined){
    return state.auth.sendOTPResponse;
  }
  return null;
}

export const getVerifyOTPResponse = (state) => {

  if(state.auth != null && state.auth !== undefined){
    return state.auth.verifyOTPResponse;
  }
  return null;
} 

export const getOTPSentStatus = (state) => {

    try {
        return state.auth.sendOTPResponse.success;        
    } catch (error) {
        return false;        
    }
}

export const getOTPVerificationStatus = (state) => {
    if(state.auth != null && state.auth !== undefined && state.auth.verifyOTPResponse !== null){
      return state.auth.verifyOTPResponse.success;
    }
    return false;        
}

export const { setSendOTPResponse, setVerifyOTPResponse, clearOTPState, setLoginFormVisibility,
  setPostAuthAction 
} = authSlice.actions
export default authSlice.reducer
