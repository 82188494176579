import { createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../constants/app_constants';

const orderSlice = createSlice({
  name: 'order',
  initialState: {
    orderId: null,
    orderFetchStatus: null,
    orderDetails: null,
    claimOrderResponse: null,
    suggestedMeals : null,
    orderClaimStatus : null,
    orderHistory: null
  },
  reducers: {
    setOrderDetails(state, action) {
      state.orderDetails = action.payload.data;
      state.orderFetchStatus = STATUS.DONE;

      try{
        const orderDetails = action.payload.data;
        if(orderDetails && orderDetails !== null 
            && orderDetails.user && orderDetails.user!==null && Object.keys(orderDetails.user).length > 0 ){
            state.orderClaimStatus = true;
        }else{
          state.orderClaimStatus = false;
        }
      }catch(err){
        console.error(err);
      }
    },
    setOrderId(state,action) {
      state.orderId = action.payload.data; 
    },
    setOrderHistory(state,action) {
      state.orderHistory = action.payload.data; 
    },
    setOrderFetchStatus(state,action) {
      state.orderFetchStatus = action.payload.status; 
    },
    clearOrderDetails(state, action){
      // state.orderId = null;
      // state.orderDetails = null;
      state.claimOrderResponse = null;
      state.orderHistory= null;
      state.orderClaimStatus = null;
    },
    setOrderClaimResponse(state, action){
        state.claimOrderResponse = action.payload.data;
      const data = action.payload.data;

      try{
        if(data.success){
          state.orderClaimStatus = true;
        }
      }catch(e){
        console.log(e);
      }
    },
    setSuggestedMeals(state, action){
      state.suggestedMeals = action.payload.data;
    },
    setOrderClaimStatus(state, action){
      state.orderClaimStatus = action.payload.status;
    }
  }
})

export const getOrderId = (state) => {

  if(state.order != null && state.order !== undefined){
    return state.order.orderId;
  }
  return null;
}

export const getOrderDetails = (state) => {
  if(state.order != null && state.order !== undefined){
    return state.order.orderDetails;
  }
  return null;
}

export const getOrderHistory = (state) => {
  if(state.order != null && state.order !== undefined){
    return state.order.orderHistory;
  }
  return null;
}

export const getOrderFetchStatus = (state) => {
  if(state.order != null && state.order !== undefined){
    return state.order.orderFetchStatus;
  }
  return null;
}

export const getOrderClaimResponse = (state) => {
    if(state.order != null && state.order !== undefined){
      return state.order.claimOrderResponse;
    }
    return null;
}

export const getSuggestedMeals = (state) => {
  if(state.order != null && state.order !== undefined){
    return state.order.suggestedMeals;
  }
  return null;
}

export const getOrderClaimStatus = (state) => {
  if(state.order != null && state.order !== undefined){
    return state.order.orderClaimStatus;
  }
  return null;
}

export const { setOrderId, setOrderDetails, setOrderClaimResponse, setOrderFetchStatus, setOrderHistory,
  clearOrderDetails, setSuggestedMeals, setOrderClaimStatus } = orderSlice.actions
export default orderSlice.reducer