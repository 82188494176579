import React from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Iconify from "../Iconify";

import '../../assets/css/SideMenu.css';
import { getUser, logoutUser } from "../../store/user/reducer";
import { clearOrderDetails, getOrderId } from "../../store/order/reducer";
import { Navigate, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { fetchOrderDetailsApi } from "../../data/network/order";
import { logEvent } from "../../utils/analytics";
import { ANALYTICS_EVENTS } from "../../constants/analytics_events";
import { getWalletDetails } from "../../store/wallet/reducer";
  
export default function SideMenu({handleClose}){

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetails = useSelector(state => getUser(state));
    const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

    const orderId = useSelector(state => getOrderId(state));
    const walletDetails = useSelector(state => getWalletDetails(state));

    return (
        <Box className="side-menu">
            <Grid container paddingTop={2} paddingBottom={2} direction="column" justifyContent="start" alignContent="start">
                <Grid item container className="heading" spacing={0.5}>
                    <Grid item container  spacing={0.5}>
                        <Grid item>
                            <Typography variant="h5">Hi </Typography>
                        </Grid>
                        {
                            userDetails && userDetails !== null && userDetails.first_name !== null
                            ? <Grid item>
                                <Typography variant="h5">{userDetails.first_name}</Typography>
                            </Grid>
                            : null
                        }
                    </Grid>
                    {
                        userDetails && userDetails !== null && userDetails.mobile_no !== null
                        ? <Grid item>
                            <Typography variant="h7">{userDetails.mobile_no}</Typography>
                        </Grid>
                        : null
                    }
                    <Box margin={4}></Box>
                </Grid>

                {/* <Grid item container className="menu-list-item ">
                    <Typography>Profile</Typography>
                </Grid> */}

                {/* <Grid item container className="menu-list-item" onClick={() => {
                    navigate('/order/history');
                    handleClose();
                }}>
                    <Typography>Order History</Typography>
                </Grid> */}

                <Grid item container className="menu-list-item" onClick={() => {
                    navigate('/wallet')
                }}>
                    <Grid container justifyContent="space-between">
                        <Typography>Wallet</Typography>
                        <Typography sx={{color:'green'}}> {walletDetails && walletDetails.balance}</Typography>
                    </Grid>
                </Grid>

                <Grid item container className="menu-list-item " onClick={() => {
                    window.open(
                        "https://play.google.com/store/apps/details?id=in.thebytes.app&utm_source=aggregator-app&utm_campaign=aggregator-user-onboarding-campaign&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                        "_blank"
                    );
                    handleClose();
                }}>
                    <Typography>Download App</Typography>
                </Grid>

                <Grid item container className="menu-list-item " onClick={() => {
                    window.open("https://the-bytes.com","_blank");  
                    handleClose();
                }}>
                    <Typography>Visit Website</Typography>
                </Grid>

                <Grid item container className="menu-list-item " onClick={() => {
                    logEvent(ANALYTICS_EVENTS['auth']['logout-button-clicked']);

                    setCookie('session', '', { path: '/' });
                    setCookie('access_token', '', { path: '/' });
                    dispatch(logoutUser());
                    dispatch(clearOrderDetails());
                    // navigate('/');
                    fetchOrderDetailsApi(dispatch, cookies, orderId);
                    // navigate(`/order/${orderId}`);
                    handleClose();
                    window.location.reload();
                }}>
                    <Typography>Sign Out?</Typography>
                </Grid>

                <Grid item mt={2} container justifyContent="center" className="btn-close">
                    <Button variant="outlined" onClick={() => {
                        handleClose();
                    }}>
                        <Typography>Close</Typography>
                    </Button>
                </Grid>

            </Grid>
        </Box>
    );
}
