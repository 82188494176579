import LoadingOverlay from 'react-loading-overlay';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module'

// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
import './assets/css/App.css';
import { getSnackbarVisibilityStatus, getSnackbarMessage,setLoadingStatus,
  getSnackbarSeverity, setSnackbarVisibilityStatus, getLoadingStatus
} from './store/layout/reducer';
import { getSession } from './data/network/login';
  // ----------------------------------------------------------------------

export default function App() {

  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies(['mobile','session', 'access_token']);

  const isLoading = useSelector(state => getLoadingStatus(state));
  const showSnackbar = useSelector(state => getSnackbarVisibilityStatus(state));
  const snackbarMessage = useSelector(state => getSnackbarMessage(state));
  const snackbarSeverity = useSelector(state => getSnackbarSeverity(state));


  useEffect(() => {
    getSession(dispatch, cookies);
  },[])

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Loading...' styles={{zIndex: '9999999999'}}>
      <ThemeProvider>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
      </ThemeProvider>
      <Snackbar open={showSnackbar} autoHideDuration={4000} onClick={() => {
        // console.log('tapped');
        dispatch(setSnackbarVisibilityStatus({'status' : false}));
      }} onClose={(e) => {
          dispatch(setSnackbarVisibilityStatus({'status' : false}));
        }} sx={{zIndex:'99999'}}>
        <Alert onClose={( ) => { }} 
          severity={snackbarSeverity} sx={{ width: '100%', zIndex:'9999' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </LoadingOverlay>
  );
}
