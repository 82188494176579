import { useState } from 'react';
// @mui
import { Card, Link, Container, Typography, Box, Tabs, Tab, Grid, Stack,List, ListItem, ListItemText, Chip, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';

import '../../assets/css/Aggregator.css';
import SuggestedDishCard from '../../components/cards/SuggestedDish';
import { dishList } from '../../constants/app_constants';
import { getSuggestedMeals } from '../../store/order/reducer';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    // minHeight: '100vh',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: theme.spacing(4, 0),
  }));
  
// ----------------------------------------------------------------------

export default function MealSection() {

    const dispatch = useDispatch();
    const suggestedMeals = useSelector(state => getSuggestedMeals(state, 'all'));

    return (        
        suggestedMeals && suggestedMeals !== null &&            
        <Container id="meal_section" maxWidth="lg" className="meal-list">
            <ContentStyle>
                <Box>
                    <Grid item container justifyContent="space-between">
                        <Grid item>
                            <Typography variant="h4" mb={2}>Suggestions</Typography>
                        </Grid>
                    </Grid>
                    {
                        suggestedMeals && suggestedMeals.map((e,i) => (
                            SuggestedDishCard({dish : e, key:i})
                        ))
                    }
                </Box>
            </ContentStyle>
        </Container>
    );
}
