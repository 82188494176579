import { SNACKBAR_SEVERITY, STATUS } from '../../constants/app_constants';
import axios from '../../utils/axios-configure';
import { API_GET_USER_DETAILS, API_UPDATE_USER_DETAILS} from "./endpoints";

import {getAccessTokenFromCookie} from './login'
import { setLoadingStatus, setSnackbarMessage, setSnackbarVisibilityStatus } from '../../store/layout/reducer';
import { setUser, setUserDetailSubmitted } from '../../store/user/reducer';
import { fetchWalletDetails } from './wallet';

export const updateUserDetails = (dispatch, cookies, data) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    dispatch(setLoadingStatus({status: true}));
    if(accessToken != null){
        axios({
            method: "post",
            url: `${API_UPDATE_USER_DETAILS}`,
            data,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
            // handle success
            if(response.status === 200){
                dispatch(setUserDetailSubmitted({status: true}));
            }else{
                dispatch(setUserDetailSubmitted({status: false}));
            }
            dispatch(setLoadingStatus({status: false}));
            return true;
        })
        .catch((err) => {
            dispatch(setUserDetailSubmitted({status: false}));
            dispatch(setLoadingStatus({status: false}));
        });
    }else{
        dispatch(setLoadingStatus({status: false}));  
        dispatch(setUserDetailSubmitted({status: false}));
    }
}

export const fetchUserDetails = async (dispatch, cookies) => {
    const  accessToken = getAccessTokenFromCookie(dispatch, cookies); 
    dispatch(setLoadingStatus({status: true}));  
    if(accessToken != null){
        axios({
            method: "get",
            url: `${API_GET_USER_DETAILS}`,
            headers: { "Content-Type": "application/json",'Authorization': `Bearer ${ accessToken}` },
        })
        .then((response) => {
            // handle success
            console.log(response);
            console.log("--------------");
            if(response.status === 200){ 
                console.log("user details fetched");
                console.log(response.data.data);
                dispatch(setUser({data: response.data.data}));

                fetchWalletDetails(dispatch, cookies);
            } 
            dispatch(setLoadingStatus({status: false}));
            return true;
        })
        .catch((err) => { 
            dispatch(setLoadingStatus({status: false}));
        });
    }
    dispatch(setLoadingStatus({status: false}));  
  }